<template>
  <div class="contents">
    <div class="title">
      <h1 v-html="$t('common.top_title', {nickname: myInfo.user_nickname})"></h1>
      <p>{{ $t('common.top_subtitle') }}</p>
    </div>
    <div class="smenu">
      <div class="mypage_sub_title">
        <a href="javascript:" @click="this.$router.back()">
          <img src="../../assets/img/btn_back.png">
        </a>{{ $t('announce.title') }}
      </div>
    </div>

    <div class="warp">
      <div class="notice_list">
        <ul>
          <li v-for="(it, idx) in announceList" :key="idx">
            <a href="javascript:" @click="onCell(idx)">
              <p>{{ it.title }}</p>
              <span>Updated {{ it.date }}</span>
              <div class="arrow">
                <img src="../../assets/img/ico_down.png">
              </div>
            </a>
            <div class="conbox" v-if="it.select == true">
              <img v-if="it.image_url != ''" :src="it.image_url"/>
              {{ it.desc }}
            </div>
          </li>
        </ul>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./Announce.scss"></style>

<script>
import { requestPost, showToast } from "../../lib/util";

export default {
  name: 'Announce',
  data() {
    let myInfo = this.$store.getters.me;
    return {
      myInfo: myInfo,
      announceList: [],

    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    onCell: function (idx) {
      let announce = this.announceList[idx];
      announce.select = !announce.select;
    },
    getList: function () {
      let _app = this;

      requestPost(
        'notice/getNotices',
        {
          uid: this.myInfo.uid
        },
        response => {
          _app.announceList = response.notices.map(it => {
            it.select = false;
            return it;
          });
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
  },
}
</script>
